/*!

 =========================================================
 * Material Dashboard React - v1.0.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 150
const drawerWidthClosed = 40
const footerHeight = 63

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
}

const container = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto'
}

const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
}

const card = {
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  height: 'fit-content',
  margin: '15px 0px 0px 5px',
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
  borderRadius: '3px',
  color: 'rgba(0, 0, 0, 0.87)',
  background: '#fff'
}

const defaultFont = {
  fontFamily: '"Inter", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em'
}

const primaryColor = '#1eae63'
const warningColor = '#ff9800'
const dangerColor = '#f44336'
const successColor = '#1eae63'
const infoColor = '#00acc1'
const roseColor = '#e91e63'
const bittersweetColor = '#FF6868'
const grayColor = '#999999'
const darkGrayColor = '#888888'
const linkHoverColor = '#89229b'
const secondaryTextColor = '#FFFFFF'
const openChipColor = '#77ed43'
const inProgressChipColor = '#ffe341'
const completedChipColor = '#21a41f'
const closedChipColor = '#ed8768'
const secondaryWarningColor = '#ed8768'
const criticalPriorityColor = '#ee5244'
const highPriorityColor = '#ee7971'
const mediumPriorityColor = '#ee864e'
const lowPriorityColor = '#e9ee7b'
const participantsCircleColor = '#ee5244'
const green = '#5cb85c'
const red = '#d9534f'
const brown = '#964B00'
const pendingWithdrawalApprovalByLeadChipColor = '#fff384'
const pendingApprovalByLeadChipColor = '#ffda6c'
const rejectedChipColor = '#ffc2c2'
const pendingApprovalByHrChipColor = '#89f96e'
const pendingApprovalByPmChipColor = '#28c67b'
const pendingWithdrawalApprovalByHrChipColor = '#81fed1'
const pendingWithdrawalApprovalByPmChipColor = '#FFBB30'
const whiteChipColor = '#FFF'
const lightGrayChipColor = '#f8f9f9'
const approvedChipColor = '#3dfc72'
const cancelledChipColor = '#ff7d60'
const successRowColor = '#e1ffca'
const failureRowColor = '#ffd5c9'
const holidayColor = '#ffece9'
const holidayWidgetColor = '#3ca9a0'
const testProjectColor = '#A70094'
const liveProjectColor = '#FFDB6C'
const completedProjectColor = '#3DFC72'
const closedProjectColor = '#797979'
const maintenanceProjectColor = '#3C6478'
const pendingProjectColor = '#8B5400'

const projectChipStyle = {
  fontWeight: 'bold'
}
const primaryBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)'
}
const infoBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)'
}
const successBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)'
}
const warningBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)'
}
const dangerBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)'
}

const orangeCardHeader = {
  background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
  ...warningBoxShadow
}
const greenCardHeader = {
  background: 'linear-gradient(60deg, #66bb6a, #43a047)',
  ...successBoxShadow
}
const redCardHeader = {
  background: 'linear-gradient(60deg, #ef5350, #e53935)',
  ...dangerBoxShadow
}
const blueCardHeader = {
  backgroundColor: '#1eae63',
  ...infoBoxShadow
}
const dodgerblueCardHeader = {
  backgroundColor: 'dodgerblue',
  ...infoBoxShadow
}

const royalblueCardHeader = {
  backgroundColor: 'royalblue',
  ...infoBoxShadow
}

const purpleCardHeader = {
  background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
  ...primaryBoxShadow
}

const grayCardHeader = {
  background: 'gray',
  ...infoBoxShadow
}

const cardActions = {
  margin: '0 20px 10px',
  paddingTop: '10px',
  borderTop: '1px solid #eeeeee',
  height: 'auto',
  ...defaultFont
}

const cardHeader = {
  margin: '-20px 15px 0',
  borderRadius: '3px',
  padding: '15px'
}

const defaultBoxShadow = {
  border: '0',
  borderRadius: '3px',
  boxShadow:
    '0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  padding: '10px 0',
  transition: 'all 150ms ease 0s'
}

export {
  approvedChipColor,
  bittersweetColor,
  blueCardHeader,
  boxShadow,
  brown,
  cancelledChipColor,
  card,
  cardActions,
  cardHeader,
  closedChipColor,
  closedProjectColor,
  completedChipColor,
  completedProjectColor,
  container,
  criticalPriorityColor,
  dangerBoxShadow,
  dangerColor,
  darkGrayColor,
  defaultBoxShadow,
  defaultFont,
  dodgerblueCardHeader,
  //variables
  drawerWidth,
  drawerWidthClosed,
  failureRowColor,
  footerHeight,
  grayCardHeader,
  grayColor,
  green,
  greenCardHeader,
  highPriorityColor,
  holidayColor,
  holidayWidgetColor,
  infoBoxShadow,
  infoColor,
  inProgressChipColor,
  lightGrayChipColor,
  linkHoverColor,
  liveProjectColor,
  lowPriorityColor,
  maintenanceProjectColor,
  mediumPriorityColor,
  openChipColor,
  orangeCardHeader,
  participantsCircleColor,
  pendingApprovalByHrChipColor,
  pendingApprovalByLeadChipColor,
  pendingApprovalByPmChipColor,
  pendingProjectColor,
  pendingWithdrawalApprovalByHrChipColor,
  pendingWithdrawalApprovalByLeadChipColor,
  pendingWithdrawalApprovalByPmChipColor,
  primaryBoxShadow,
  primaryColor,
  projectChipStyle,
  purpleCardHeader,
  red,
  redCardHeader,
  rejectedChipColor,
  roseColor,
  royalblueCardHeader,
  secondaryTextColor,
  secondaryWarningColor,
  successBoxShadow,
  successColor,
  successRowColor,
  testProjectColor,
  transition,
  warningBoxShadow,
  warningColor,
  whiteChipColor
}
