import { sortBy } from 'lodash'

import * as types from 'actions/actionTypes'
import { sortValue } from 'helpers/applicationHelper'
import { isTicketOverDueStatus, isTicketReleased } from 'helpers/ticketsHelper'
import {
  generateUserFormTeamChangeHistories,
  updatedarchivedTaskData,
  updatedarchivedWorkFlowData,
  updatedClearanceData,
  updatedOffboardingUsersData
} from 'helpers/userHelper'

import initialState from './initialState'

export const user = (state = initialState, action) => {
  let allUsers = []
  let index
  let offboardingUsers = []
  let offboardingUserIndex
  let indexToRemove
  let verifiedUserIndex
  let onboardingUserIndex
  switch (action.type) {
    case types.SET_USERS_TAB:
      return { ...state, tab: action.payload.tab }

    case types.FETCH_USERS_SUCCESS:
      return { ...state, allUsers: action.payload, userLoader: true }

    case types.FETCH_VERIFICATION_USERS_SUCCESS:
      return { ...state, verificationUsers: action.payload }

    case types.USER_BULK_VERIFICATION_USERS_SUCCESS:
      action.payload.bulk_verified_users.forEach((verifiedUser) => {
        verifiedUserIndex = state.verificationUsers.findIndex((user) => user.id === verifiedUser.onboarding_id)
        if (verifiedUserIndex !== -1) {
          state.verificationUsers[verifiedUserIndex] = verifiedUser
        }
      })
      return { ...state, verificationUsers: [...state.verificationUsers] }

    case types.FETCH_ONBOARDING_USERS_SUCCESS:
      return { ...state, onboardingUsers: action.payload }

    case types.FETCH_CREATION_LOGS_SUCCESS:
      return { ...state, creationLogs: action.payload }

    case types.EDIT_ONBOARDING_USERS_SUCCESS:
      onboardingUserIndex = state.onboardingUsers.findIndex(
        (user) =>
          user.basic_data.id ===
          (action.payload.flag
            ? action.payload.activeUser
              ? action.payload.user.id
              : action.payload.id
            : action.payload.basic_data.id)
      )
      if (onboardingUserIndex !== -1) {
        if (action.payload.flag && (action.payload.inactiveUser || action.payload.activeUser))
          state.onboardingUsers[onboardingUserIndex].deleted_at = action.payload.deleted_at
        else state.onboardingUsers[onboardingUserIndex] = action.payload
      }
      return { ...state, onboardingUsers: [...state.onboardingUsers] }

    case types.FETCH_SEARCH_USERS_SUCCESS: {
      let users = state.searchableUsers[action.payload.key]
        ? [...state.searchableUsers[action.payload.key], ...action.payload.users]
        : action.payload.users
      const uniqueObjects = sortValue([...new Map(users.map((user) => [user.value, user])).values()])
      let newSearchableUsers = { ...state.searchableUsers }
      newSearchableUsers[action.payload.key] = uniqueObjects
      return {
        ...state,
        searchableUsers: newSearchableUsers
      }
    }
    case types.FETCH_USERS_FAILURE:
      return state

    case types.FETCH_SEARCH_USERS_FAILURE:
      return state

    case types.FETCH_DEPARTMENT_USERS_SUCCESS:
      return { ...state, departmentUsers: action.payload }

    case types.FETCH_DEPARTMENT_USERS_FAILURE:
      return state

    case types.DEACTIVATE_USER_SUCCESS:
      allUsers = [...state.allUsers]
      if (state.offboardingUsers.users) {
        offboardingUsers = [...state.offboardingUsers.users]
        offboardingUserIndex = state.offboardingUsers.users.findIndex((user) => user.id === action.payload.id)
        if (offboardingUserIndex !== -1) offboardingUsers[offboardingUserIndex] = action.payload
      }

      if (action.payload.ending_date) {
        allUsers.splice(
          state.allUsers.findIndex((user) => user.id === action.payload.id),
          1
        )

        if (action.payload.deleted_at) {
          let index = state.offboardingUsers.users.findIndex((user) => user.id === action.payload.id)
          if (action.payload.clearance_time) offboardingUsers.splice(index, 1)
          else offboardingUsers[index] = action.payload
        }
        state.selectUsers.splice(
          state.selectUsers.findIndex((user) => user.value === action.payload.id),
          1
        )

        Object.keys(state.searchableUsers).forEach((key) => {
          state.searchableUsers[key].splice(
            state.searchableUsers[key].findIndex((user) => user.value === action.payload.id),
            1
          )
        })
      } else {
        allUsers.splice(
          state.allUsers.findIndex((user) => user.id === action.payload.id),
          1,
          action.payload
        )
      }
      return {
        ...state,
        allUsers: [...allUsers],
        userSubordinates: [],
        clearanceData: {},
        offboardingUsers: [...offboardingUsers]
      }

    case types.DEACTIVATE_USER_FAILURE:
      return state

    case types.ACTIVATE_USER_SUCCESS:
      allUsers = [...state.allUsers]

      allUsers.splice(
        state.allUsers.findIndex((user) => user.id === action.payload.user.id),
        1
      )

      return { ...state, allUsers: [...allUsers] }

    case types.ACTIVATE_USER_FAILURE:
      return state

    case types.EDIT_USER_SUCCESS: {
      let newAllUsers = [...state.allUsers]
      let newAllUserProfiles = { ...state.allUserProfiles }

      let pendingVerifiedUsers = [...state.verificationUsers]

      if (action.payload.flag === 'verified') {
        index = state.verificationUsers.findIndex((user) => user.id === action.payload.onboarding_id)
        pendingVerifiedUsers.splice(index, 1)
      } else {
        index = state.allUsers.findIndex((user) => user.id === action.payload.id)
        newAllUsers[index] = action.payload
        newAllUserProfiles[action.payload.id] = action.payload
      }
      return {
        ...state,
        allUsers: newAllUsers,
        allUserProfiles: newAllUserProfiles,
        verificationUsers: pendingVerifiedUsers
      }
    }
    case types.VERIFICATION_STATUS_SUCCESS: {
      let userIndex = state.verificationUsers.findIndex((user) => user.id === action.payload.id)
      let updatedAllUsers = [...state.verificationUsers]
      if (action.payload.status === 'onboarded') updatedAllUsers.slice(userIndex, 1)
      else updatedAllUsers[userIndex] = { ...action.payload }

      return { ...state, verificationUsers: updatedAllUsers }
    }

    case types.EDIT_USER_FAILURE:
      return state

    case types.UPDATE_RAW_USER_SUCCESS: {
      if (action.formType == 'basic_info') {
        action.payload.team_change_histories_attributes = generateUserFormTeamChangeHistories(action)
      }

      return { ...state, rawUser: { ...state.rawUser, [action.formType]: action.payload } }
    }

    case types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        allUserProfiles: {
          ...state.allUserProfiles,
          [action.payload.id]: action.payload.is_current_user
            ? {
                ...state.allUserProfiles[action.payload.id],
                ...action.payload,
                is_current_user: undefined
              }
            : action.payload
        }
      }

    case types.GET_PROFILE_FAILURE:
      return state

    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        allUserProfiles: { ...state.allUserProfiles, [action.payload.id]: action.payload },
        profileUpdated: true
      }
    case types.UPDATE_OFFBOARDING_USER_SETTINGS_SUCCESS: {
      const userIndex = state.offboardingUsers.findIndex((user) => user.id === action.payload.id)
      let updatedOffboardedUsers = [...state.offboardingUsers]

      updatedOffboardedUsers[userIndex].settings = action.payload.settings

      return { ...state, offboardingUsers: updatedOffboardedUsers }
    }

    case types.HIDE_MODAL:
      return { ...state, userCreateFormErrors: '' }

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        allUsers: sortBy([...state.allUsers, action.payload], (user) => user.name),
        userCreateFormErrors: ''
      }

    case types.CREATE_USER_FAILURE:
      return { ...state, userCreateFormErrors: action.payload.errors }

    case types.CLEAR_CREATE_USER_FORM_ERRORS:
      return { ...state, userCreateFormErrors: null }

    case types.RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordSuccess: true }

    case types.RESET_PASSWORD_FAILURE:
      return state

    case types.FETCH_DASHBOARD_SUCCESS:
      return { ...state, dashboard: action.payload }

    case types.FETCH_DASHBOARD_FAILURE:
      return state

    case types.FETCH_TEAM_ANALYTICS_SUCCESS:
      return { ...state, teamAnalytics: action.payload }

    case types.FETCH_TEAM_ANALYTICS_FAILURE:
      return state

    case types.SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, resetPasswordMailStatus: { ...state.resetPasswordMailStatus, mailSent: true } }

    case types.SEND_RESET_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        resetPasswordMailStatus: { ...state.resetPasswordMailStatus, mailSent: false, errors: action.payload.errors }
      }

    case types.CLEAR_RESET_PASSWORD_STATUS:
      return {
        ...state,
        resetPasswordMailStatus: { ...state.resetPasswordMailStatus, mailSent: false, errors: '' }
      }

    case types.FETCH_ORGANO_CHART_SUCCESS:
      return { ...state, organoChartJSON: action.payload }

    case types.FETCH_ORGANO_CHART_FAILURE:
      return state

    case types.RESEND_WELCOME_EMAIL_SUCCESS:
      return state

    case types.RESEND_WELCOME_EMAIL_FAILURE:
      return state

    case types.FIELDS_FILLED_SUCCESS:
      return {
        ...state,
        allUserProfiles: { ...state.allUserProfiles, [action.payload.id]: action.payload },
        fieldsSubmitSuccess: true
      }

    case types.FIELDS_FILLED_FAILURE:
      return state

    case types.FETCH_SELECT_USERS_SUCCESS:
      return { ...state, selectUsers: action.payload }

    case types.FETCH_SELECT_REPORTING_USERS_SUCCESS:
      return { ...state, reportingToUsers: action.payload }

    case types.FETCH_SELECT_USERS_FAILURE:
      return state

    case types.RESET_PROFILE_UPDATED:
      return { ...state, profileUpdated: false }

    case types.FETCH_ASSIGN_TO_USER_SUCCESS:
      return {
        ...state,
        usersForAssignItem: action.payload
      }

    case types.FETCH_ASSIGN_TO_USER_FAILURE:
      return state

    case types.FETCH_BLOCKED_USERS_SUCCESS:
      return { ...state, blockedUsers: action.payload }

    case types.FETCH_BLOCKED_USERS_FAILURE:
      return state

    case types.FETCH_USER_SUBORDINATES_SUCCESS:
      return {
        ...state,
        userSubordinates: action.payload,
        clearanceData: action.flag ? state.clearanceData : {}
      }

    case types.FETCH_USER_SUBORDINATES_FAILURE:
      return state

    case types.HANDLE_USER_SEARCH_CHANGE:
    case types.HANDLE_DATE_RANGE_SELECTED_DATES:
      return { ...state, search: { ...state.search, ...action.payload } }

    case types.FETCH_HEADER_DATA_SUCCESS:
      return { ...state, headerData: action.payload }

    case types.FETCH_HEADER_DATA_FAILURE:
      return state

    case types.FETCH_USER_CLEARANCE_DATA_SUCCESS:
      return {
        ...state,
        clearanceData: { ...state.clearanceData, [action.payload.user_id]: action.payload }
      }

    case types.FETCH_USER_CLEARANCE_DATA_FAILURE:
      return state

    case types.FETCH_USERS_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, csvAttributes: action.payload, csvData: [] }

    case types.FETCH_USERS_CSV_ATTRIBUTES_FAILURE:
      return state

    case types.FETCH_EMPLOYMENT_TYPES_SUCCESS:
      return { ...state, employmentTypes: action.payload }

    case types.FETCH_EMPLOYMENT_TYPES_FAILURE:
      return state

    case types.RESET_USER_CLEARANCE_DATA:
      return { ...state, clearanceData: {} }

    case types.SHOW_USER_LOADER:
      return { ...state, userLoader: true }

    case types.HIDE_USER_LOADER:
      return { ...state, userLoader: false }

    case types.SHOW_ORGANO_CHART_LOADER:
      return { ...state, organoChartLoader: true }

    case types.HIDE_ORGANO_CHART_LOADER:
      return { ...state, organoChartLoader: false }

    case types.FETCH_DAY_FEEDBACK_SUCCESS:
      return { ...state, todayFeedbackPending: action.payload.today_feedback_pending }

    case types.FETCH_DAY_FEEDBACK_FAILURE:
      return state

    case types.CREATE_DAY_FEEDBACK_SUCCESS:
      return { ...state, todayFeedbackPending: action.payload.today_feedback_pending }

    case types.CREATE_DAY_FEEDBACK_FAILURE:
      return state

    case types.FETCH_OFFBOARDING_USERS_SUCCESS:
      return { ...state, offboardingUsers: action.payload }

    case types.UPDATE_OFFBOARDING_USER_TASKS_SUCCESS: {
      const updatedUsers = state.offboardingUsers?.map((user) =>
        user.id === action.payload.id ? action.payload : user
      )
      return { ...state, offboardingUsers: updatedUsers }
    }

    case types.FETCH_BULK_USERS_CSV_ATTRIBUTES_SUCCESS:
      return { ...state, csvAttributesForBulkUser: action.payload, csvErrors: [] }

    case types.FETCH_BULK_USERS_CSV_ATTRIBUTES_FAILURE:
      return state

    case types.UPDATE_BULK_USER_CSV_ERRORS: {
      let errors = state.csvErrors.filter((str) => !str.includes(`row #${action.payload}:`))

      return { ...state, csvErrors: errors }
    }

    case types.UPLOAD_BULK_USER_CSV_FAILURE:
      return { ...state, csvErrors: [...state.csvErrors, ...action.payload.errors] }

    case types.UPDATE_DEPARTMENT_CLEARANCE_DATA_SUCCESS: {
      let moderatorOfDepartment = state.clearanceData[action.userId].moderator_of_department
      indexToRemove = moderatorOfDepartment.findIndex((dept) => dept.id === action.payload.id)
      if (moderatorOfDepartment[indexToRemove].moderator_user_id !== action.payload.moderator_user_id) {
        moderatorOfDepartment.splice(indexToRemove, 1)
      } else {
        moderatorOfDepartment[indexToRemove] = action.payload
      }
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(
          state.offboardingUsers,
          action.userId,
          state.clearanceData[action.userId].total_pending_tasks
        ),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            moderator_of_department: moderatorOfDepartment
          }
        }
      }
    }

    case types.UPDATE_INVENTORY_CLEARANCE_DATA_SUCCESS: {
      let inventory = state.clearanceData[action.userId].inventory
      let pendingTask = state.clearanceData[action.userId].total_pending_tasks
      indexToRemove = inventory.findIndex((item) => item.item_id === action.payload.item_id)
      if (inventory[indexToRemove].assigned_to !== action.payload.assigned_to) {
        inventory.splice(indexToRemove, 1)
        pendingTask = pendingTask - 1
      }
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTask),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            total_pending_tasks: pendingTask,
            inventory: inventory
          }
        }
      }
    }

    case types.UPDATE_DNARS_CLEARANCE_DATA_SUCCESS: {
      let pendingTaskCount = state.clearanceData[action.userId].total_pending_tasks - 1
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            total_pending_tasks: pendingTaskCount,
            has_pending_dnars: updatedClearanceData(
              state.clearanceData[action.userId].has_pending_dnars,
              action.payload.id
            )
          }
        }
      }
    }

    case types.ENCASH_OFFBOARDING_USER_LEAVES_SUCCESS: {
      let pendingTaskCount = state.clearanceData[action.userId].total_pending_tasks - 1
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            total_pending_tasks: pendingTaskCount,
            encashable_leaves: updatedClearanceData(
              state.clearanceData[action.userId].encashable_leaves,
              action.leaveTypeId,
              'leave_type_id'
            )
          }
        }
      }
    }

    case types.UPDATE_TASKS_CLEARANCE_DATA_SUCCESS: {
      let pendingTaskCount = state.clearanceData[action.userId].total_pending_tasks - 1
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            total_pending_tasks: pendingTaskCount,
            assignee_of_tasks: updatedClearanceData(
              state.clearanceData[action.userId].assignee_of_tasks,
              action.payload.id
            )
          }
        }
      }
    }

    case types.UPDATE_ARCHIVED_TASKS_CLEARANCE_DATA_SUCCESS: {
      let pendingTaskCount =
        state.clearanceData[action.userId].total_pending_tasks - 1 + action.payload.successor_user_tasks?.length
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            total_pending_tasks: pendingTaskCount,
            user_tasks: updatedarchivedTaskData(state.clearanceData[action.userId].user_tasks, action.payload)
          }
        }
      }
    }

    case types.UPDATE_ARCHIVED_WORKFLOW_CLEARANCE_DATA_SUCCESS: {
      let archived_task = state.clearanceData[action.userId].user_tasks
      let archived_tasks_count = 0

      Object.keys(archived_task).forEach((key) => {
        const indexToRemove = archived_task[key].findIndex((task) =>
          action.payload.userTasks.map((task) => task.id).includes(task.id)
        )
        if (indexToRemove === 0) {
          archived_tasks_count = archived_task[key].length
        }
      })

      let pendingTaskCount = state.clearanceData[action.userId].total_pending_tasks - archived_tasks_count

      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            total_pending_tasks: pendingTaskCount,
            user_tasks: updatedarchivedWorkFlowData(state.clearanceData[action.userId].user_tasks, action.payload)
          }
        }
      }
    }

    case types.UPDATE_CLAIMS_CLEARANCE_DATA_SUCCESS: {
      let pendingTaskCount = state.clearanceData[action.userId].total_pending_tasks - 1
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            total_pending_tasks: pendingTaskCount,
            pending_claims: updatedClearanceData(state.clearanceData[action.userId].pending_claims, action.payload.id)
          }
        }
      }
    }

    case types.UPDATE_LEAVES_CLEARANCE_DATA_SUCCESS: {
      let leaves = state.clearanceData[action.userId].pending_leaves_withdrawal
      indexToRemove = leaves.findIndex((leave) => leave.id === action.payload.leave_id)
      if (indexToRemove === -1) {
        return {
          ...state,
          clearanceData: {
            ...state.clearanceData,
            [action.userId]: {
              ...state.clearanceData[action.userId],
              total_pending_tasks: state.clearanceData[action.userId].total_pending_tasks - 1,
              pending_leaves: updatedClearanceData(
                state.clearanceData[action.userId].pending_leaves,
                action.payload.leave_id
              )
            }
          }
        }
      }
      leaves.splice(indexToRemove, 1)

      let pendingTaskCount = state.clearanceData[action.userId].total_pending_tasks - 1
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            pending_leaves_withdrawal: leaves
          }
        }
      }
    }

    case types.UPDATE_TICKETS_CLEARANCE_DATA_SUCCESS: {
      let approverInTickets = state.clearanceData[action.userId].approver_in_tickets

      if (action.payload.ticket_approval) {
        indexToRemove = approverInTickets.findIndex(
          (ticket) => ticket.id === action.payload.ticket_approval.approvable_id
        )
        if (['rejected', 'approved'].includes(action.payload.ticket_event.changed_to)) {
          approverInTickets.splice(indexToRemove, 1)
          return {
            ...state,
            clearanceData: {
              ...state.clearanceData,
              [action.userId]: {
                ...state.clearanceData[action.userId],
                approver_in_tickets: approverInTickets
              }
            }
          }
        }
      }

      if (action.payload.current_status) {
        indexToRemove = approverInTickets.findIndex((ticket) => ticket.id === action.payload.id)
        if (isTicketReleased(action.payload.current_status.status)) {
          approverInTickets.splice(indexToRemove, 1)
        } else if (isTicketOverDueStatus(action.payload.current_status.status)) {
          approverInTickets[indexToRemove].status = action.payload.current_status.status
        }
      }

      let pendingTaskCount = state.clearanceData[action.userId].total_pending_tasks
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            approver_in_tickets: approverInTickets
          }
        }
      }
    }

    case types.UPDATE_TRANSFERRED_DNARS_CLEARANCE_DATA_SUCCESS:
      return {
        ...state,
        offboardingUsers: updatedOffboardingUsersData(
          state.offboardingUsers,
          action.userId,
          state.clearanceData[action.userId].total_pending_tasks
        ),
        clearanceData: {
          ...state.clearanceData,
          [action.userId]: {
            ...state.clearanceData[action.userId],
            has_dnars_in_fund: []
          }
        }
      }

    case types.REPLACE_DISCARD_TICKET_APPROVAL_SUCCESS: {
      if (action.payload?.discard) {
        let pendingTaskCount =
          state.clearanceData[action.userId].total_pending_tasks -
          state.clearanceData[action.userId].approver_in_tickets.length

        return {
          ...state,
          offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
          clearanceData: {
            ...state.clearanceData,
            [action.userId]: {
              ...state.clearanceData[action.userId],
              approver_in_tickets: [],
              total_pending_tasks: pendingTaskCount
            }
          }
        }
      } else if (action.payload?.replaced || !action.payload) {
        let pendingTaskCount =
          state.clearanceData[action.userId].total_pending_tasks -
          state.clearanceData[action.userId].approver_in_ticket_categories?.length
        return {
          ...state,
          offboardingUsers: updatedOffboardingUsersData(state.offboardingUsers, action.userId, pendingTaskCount),
          clearanceData: {
            ...state.clearanceData,
            [action.userId]: {
              ...state.clearanceData[action.userId],
              total_pending_tasks: pendingTaskCount,
              approver_in_ticket_categories: []
            }
          }
        }
      }
      return state
    }

    case types.FETCH_USER_EXIT_REASONS_SUCCESS:
      return { ...state, exitReasons: { exitReasons: action.payload } }

    case types.CREATE_USER_EXIT_REASON_SUCCESS:
      return {
        ...state,
        exitReasons: { exitReasons: [...state.exitReasons.exitReasons, action.payload] }
      }

    default:
      return state
  }
}
