import * as types from 'actions/actionTypes'

import initialState from './initialState'

export const currency = (state = initialState.currency, action) => {
  switch (action.type) {
    case types.FETCH_CURRENCIES_SUCCESS:
      return { ...state, currencies: action.payload }

    default:
      return state
  }
}
