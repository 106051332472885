import { memo, Suspense, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, Route, Routes as Switch } from 'react-router-dom'
import withRouter from 'hocs/withRouter'
import { Page404 } from 'maple-storybook'
import { compose } from 'redux'
import appRoutes from 'routes/app.jsx'
import Unauthorized from 'views/Errors/Unauthorized'

import image404 from 'assets/img/image404.png'
import { Delay } from 'components'
import { removeProfilePartFromURL } from 'helpers/applicationHelper'
import { checkPermission } from 'helpers/permissionsHelper'

const requireSignIn = (Component) =>
  compose(
    withRouter,
    connect((state) => ({ authUser: state.reduxTokenAuth.currentUser }))
  )(({ authUser, ...props }) => {
    useEffect(() => {
      if (authUser.hasVerificationBeenAttempted && !authUser.isSignedIn && !authUser.isLoading) {
        props.router.navigate('/login')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser])
    return authUser ? <Component {...props} /> : null
  })

function Routes({ permissions, user, company, router: { navigate, location } }) {
  const verifyModuleEnabled = (accessConfig) =>
    accessConfig?.alwaysEnabled || (company.modules && company.modules[accessConfig?.moduleName])

  const checkAccess = (prop) => {
    if (!prop.requiredPermissions) return requireSignIn(prop.component)
    if (prop.requiredPermissions instanceof Array) {
      let allowed = false
      if (prop.atLeastOne) {
        prop.requiredPermissions.forEach((requiredPermission) => {
          if (requiredPermission.onlyFull) {
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isFull()
          }
          if (requiredPermission.onlyFullOrViewFullOrCreateRestricted) {
            allowed =
              checkPermission(
                permissions,
                requiredPermission.group,
                requiredPermission.entity,
                requiredPermission.action
              ).isFullOrViewFull() ||
              checkPermission(
                permissions,
                requiredPermission.group,
                requiredPermission.entity,
                requiredPermission.action
              ).isCreateRestricted()
          } else if (requiredPermission.onlyFullOrViewFull) {
            allowed = checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isFullOrViewFull()
          } else if (
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isViewable() ||
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isRestricted() ||
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isRestrictedDepartment() ||
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isRestrictedLocation() ||
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isRestrictedLocationDepartment() ||
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isMyReports() ||
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isDepartmentalView() ||
            checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isCreateRestricted() ||
            !checkPermission(
              permissions,
              requiredPermission.group,
              requiredPermission.entity,
              requiredPermission.action
            ).isNone()
          )
            allowed = true
        })
      } else {
        allowed = prop.requiredPermissions.every(
          (component) =>
            checkPermission(permissions, component.group, component.entity, component.action).isViewable() === true
        )
      }

      if (allowed) return requireSignIn(prop.component)
      else return requireSignIn(Unauthorized)
    }

    if (
      checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isFull() ||
      checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isViewable() ||
      checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isRestrictedLocation() ||
      checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isRestrictedLocationDepartment() ||
      checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isMyReports() ||
      checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isDepartmentalView() ||
      checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isEditRestricted() ||
      checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isViewRestricted() ||
      !checkPermission(
        permissions,
        prop.requiredPermissions.group,
        prop.requiredPermissions.entity,
        prop.requiredPermissions.action
      ).isNone()
    ) {
      return requireSignIn(prop.component)
    }
  }

  return (
    <div className="routes">
      <Suspense fallback={<Delay />}>
        <Switch>
          <Route
            path="*"
            element={
              <Page404
                errorCode="404! Page Not Found"
                navigateBack={() => navigate(-1, { replace: true })}
                navigateHome={() => navigate('/', { replace: true })}
                notFoundImg={<img height="500" src={image404} alt="notfound" className="no-found-image" />}
              />
            }
          />
          {appRoutes
            .filter((route) => !(user.support_staff && route.path === '/settings'))
            .map((route, key) => {
              if (route.path === '/people/new' && company.create_users_limit_exceeded) {
                return <Route key={key} path={route.path} Component={Unauthorized} exact={route.exact} />
              } else if (location.pathname.includes('tickets') && location.pathname.includes('profile')) {
                window.location.pathname = removeProfilePartFromURL(location.pathname)
              } else if (route.redirect) {
                return <Route key={key} path={route.path} element={<Navigate to={route.to} replace />} />
              } else if (route.children) {
                return route.children.map(
                  (nestRoute, nestRouteKey) =>
                    verifyModuleEnabled(nestRoute.accessConfig ? nestRoute.accessConfig : route.accessConfig) && (
                      <Route
                        key={nestRouteKey}
                        path={nestRoute.path}
                        Component={checkAccess(route)}
                        index={nestRoute.indexRoute}
                      />
                    )
                )
              } else {
                return (
                  verifyModuleEnabled(route.accessConfig) && (
                    <Route key={key} path={route.path} Component={checkAccess(route)} exact={route.exact} />
                  )
                )
              }
            })}
        </Switch>
      </Suspense>
    </div>
  )
}

const mapStateToProps = (state) => {
  let user = state.reduxTokenAuth.currentUser.attributes
  let currentUser = state.reduxTokenAuth.currentUser

  return {
    permissions: state.permissions.userPermissions,
    isSignedIn: currentUser.isSignedIn,
    user: state.users.allUserProfiles[user.id],
    company: state.currentCompany
  }
}
function arePropsEqual(prevProps, nextProps) {
  if (prevProps.user?.fields_fill !== nextProps.user?.fields_fill) return false
  if (prevProps.user?.settings !== nextProps.user?.settings) return true
  else
    return (
      prevProps.permissions === nextProps.permissions &&
      prevProps.company === nextProps.company &&
      prevProps.router.location.pathname === nextProps.router.location.pathname &&
      prevProps.isSignedIn === nextProps.isSignedIn
    )
}

export default withRouter(connect(mapStateToProps, null)(memo(Routes, arePropsEqual)))
