import { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { ActionCableConsumer, ActionCableProvider } from '@thrash-industries/react-actioncable-provider'
import ActionCable from 'actioncable'
import { Avatar, Dialog, IconButton } from 'maple-storybook'
import { CheckCircle, Close, HighlightOff } from 'maple-storybook/assets'
import QRCode from 'qrcode'

import { HIDE_MODAL } from 'actions/modal'
import somethingWentWrong from 'assets/img/something_went_wrong.jpg'
import { Button } from 'components'
import { avatarImageProps, checkValueNull, errorsAlert, getCurrentTimeZone } from 'helpers/applicationHelper'
import { API_WS_LINK } from 'helpers/authHelper'
import { TWELVE_HOUR_TIME_FORMAT } from 'helpers/constants'
import { formatDate } from 'helpers/datesHelper'

import './qrModalStyles.scss'

const QrModal = ({
  redeemedEntry,
  avatar,
  userId,
  open,
  userName,
  closeModal,
  note,
  currentCompany,
  deleteResource
}) => {
  const [imageUrl, setImageUrl] = useState(null)
  const [redeemed, setRedeemed] = useState(null)
  const cable = ActionCable.createConsumer(API_WS_LINK())

  const generateQrCode = async () => {
    try {
      const response = await QRCode.toDataURL(
        `${redeemedEntry.id}?token=${redeemedEntry.token}&company=${currentCompany.subdomain}&user_id=${userId}`
      )
      setImageUrl(response)
    } catch (error) {
      setImageUrl(somethingWentWrong)
    }
  }

  useEffect(() => {
    generateQrCode()
    return () => {
      cable.disconnect()
    }
  }, [])

  const handleReceived = (response) => {
    setRedeemed(response.success)
    !response.success && errorsAlert(response.errors)
  }

  const currentTimeZone = useSelector(getCurrentTimeZone)

  return (
    <ActionCableProvider cable={cable}>
      <ActionCableConsumer
        channel="RedeemSubscriptionChannel"
        onReceived={handleReceived}
        onDisconnected={() => cable.disconnect()}>
        <Dialog
          className="qr-modal"
          open={open}
          onClose={closeModal}
          disableEscapeKeyDown={false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <IconButton color="inherit" onClick={() => closeModal()} aria-label="Close" className="margin-left-auto">
            <Close />
          </IconButton>
          <div className="modal-qr-dialog">
            <div className="modal-qr-avatar">
              <Avatar src={avatar} width={101} height={101} imgProps={avatarImageProps()} />
            </div>
            <div className="modal-qr-user-name">{userName}</div>
            <div className="modal-qr-user-id">ID-{userId}</div>
            {checkValueNull(redeemed) && (
              <>
                <div className="qr-code">
                  <img className="qr-image" src={imageUrl} alt={imageUrl} />
                </div>
                <div className="modal-qr-note">{note}</div>
              </>
            )}
            {redeemed && (
              <>
                <div className="qr-success-icon">
                  <CheckCircle />
                </div>
                <div className="modal-qr-success-note">Redeemed Successfully</div>
                <span>
                  Availed at: {formatDate(new Date(), currentTimeZone, TWELVE_HOUR_TIME_FORMAT).toUpperCase()}
                </span>
              </>
            )}
            {!checkValueNull(redeemed) && !redeemed && (
              <>
                <div className="qr-unsuccessful-icon">
                  <HighlightOff />
                </div>
                <div className="modal-qr-failure-note">Cannot be Redeemed</div>
              </>
            )}
            {(checkValueNull(redeemed) || !redeemed) && (
              <Button onClick={deleteResource.bind(this)}>Redeem Manually</Button>
            )}
          </div>
        </Dialog>
      </ActionCableConsumer>
    </ActionCableProvider>
  )
}

const mapStateToProps = (state) => ({
  currentCompany: state.currentCompany
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteResource: () => {
      dispatch(ownProps.onConfirmation)
      dispatch(HIDE_MODAL)
    },
    closeModal: () => {
      dispatch(HIDE_MODAL)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QrModal)
