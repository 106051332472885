import { Badge, Tooltip } from 'maple-storybook'
import { AccessTime, CheckCircle, Circle, Drafts, Email } from 'maple-storybook/assets'
import { fileAttachmentThumbnails } from 'maple-storybook/utils'
import moment from 'moment'
import { stringify } from 'qs'

import ReOpen from 'assets/img/re-open.svg'
import { Button } from 'components'
import { checkArrayEmpty, checkArrayPresence } from 'helpers/applicationHelper'
import { DATE_TIME_FORMAT, DAY_IN_MINUTES } from 'helpers/constants'
import { differenceBetweenDates, differenceBetweenMinutes, formatDate } from 'helpers/datesHelper'
import { checkPermission } from 'helpers/permissionsHelper'

export const ticketsTabs = [
  {
    value: '/tickets',
    label: 'Inbox',
    href: '/tickets'
  },
  {
    value: '/tickets/sent',
    label: 'Sent',
    href: '/tickets/sent'
  },
  {
    value: '/tickets/dashboard',
    label: 'Dashboard',
    href: '/tickets/dashboard'
  }
]

export const ticketPriorities = [
  { label: 'Critical', value: '0' },
  { label: 'High', value: 1 },
  { label: 'Low', value: 3 },
  { label: 'Medium', value: 2 }
]

export const getTicketPageTabs = (pendingApproval) => {
  return [
    { value: 'main', label: 'Main' },
    {
      value: 'approval',
      label: pendingApproval ? (
        <div className="d-flex">
          Approval &nbsp;
          <div className="pending-approval">
            <div className="circle">
              <div className="text">1</div>
            </div>
          </div>
        </div>
      ) : (
        'Approval'
      )
    }
  ]
}

export const isTicketReleased = (ticketStatus) => isTicketCompleted(ticketStatus) || isTicketClosed(ticketStatus)

export const isTicketCompleted = (ticketStatus) => ticketStatus === 'completed'

export const isTicketClosed = (ticketStatus) => ticketStatus === 'closed'

export const formatTicketDescription = (description) =>
  description.length > 200 ? description.substring(0, 200).concat('...') : description

export const canAddApprovers = (ticket, ticketApprovers, userId) =>
  ticket.creator.id === userId || ticket.assignee?.id === userId || ticketApprovers.includes(userId)

export const renderPriorityColor = (priority) => {
  if (priority === 'medium') return '#ee864e'
  else if (priority === 'critical') return '#ee5244'
  else if (priority === 'high') return '#ee7971'
  else if (priority === 'low') return '#e9ee7b'
}

export const mergeSortedArrays = (arr1, arr2, mergedArrayLength, arr1Key = 'created_at', arr2Key = 'created_at') => {
  let mergedArray = Array(mergedArrayLength).fill(0)
  let i = 0,
    j = 0,
    k = 0

  while (i < arr1.length && j < arr2.length) {
    if (moment(arr1[i][arr1Key]) < moment(arr2[j][arr2Key])) {
      mergedArray[k++] = arr1[i++]
    } else {
      mergedArray[k++] = arr2[j++]
    }
  }
  while (i < arr1.length) mergedArray[k++] = arr1[i++]
  while (j < arr2.length) mergedArray[k++] = arr2[j++]
  return mergedArray
}

export const outDueDate = (ticket, format = 'MMM DD YYYY hh:mm a') => {
  if (
    differenceBetweenMinutes(moment(new Date(ticket.deadline)).format(format), moment(new Date()).format(format)) >
    DAY_IN_MINUTES
  )
    return differenceBetweenDates(moment(new Date(ticket.deadline)).format(format), moment(new Date()).format(format))
  else {
    return 'less than 1'
  }
}

export const renderTicketDueDate = (ticket) => formatDate(ticket.deadline, null, DATE_TIME_FORMAT)

export const existsInFilter = (filters, ticket) =>
  (filters.open && ticket.status === 'open') ||
  (filters.inProgress && ticket.status === 'in_progress') ||
  (filters.completed && ticket.status === 'completed') ||
  (filters.closed && ticket.status === 'closed')

export const renderCommentAttachment = (attachments) =>
  attachments.map((attachment) => (
    <div key={attachment.url ? attachment.url : attachment.media} className="attachment-block">
      <a href={attachment.url ? attachment.url : attachment.media} target="_blank" rel="noreferrer">
        {fileAttachmentThumbnails(attachment, 'attachment-size')}
      </a>
    </div>
  ))

export const renderStatusChips = (status) => {
  switch (status) {
    case 'open':
      return (
        <Badge
          label={
            <>
              <Circle className="ml-half" fontSize="10" /> Open
            </>
          }
          size="md"
          className="open-chip"
        />
      )
    case 'reopened':
      return (
        <Badge
          label={
            <>
              <Circle className="ml-half" fontSize="10" /> Re-Opened
            </>
          }
          className="reopened-chip"
        />
      )
    case 'in_progress':
      return (
        <Badge
          label={
            <>
              <Circle className="ml-half" fontSize="10" /> In Progress
            </>
          }
          className="in-progress-chip"
        />
      )
    case 'completed':
      return (
        <Badge
          label={
            <>
              <Circle className="ml-half" fontSize="10" /> Completed
            </>
          }
          className="completed-chip"
        />
      )
    case 'closed':
      return (
        <Badge
          label={
            <>
              <Circle className="ml-half" fontSize="10" /> Closed
            </>
          }
          className="closed-chip"
        />
      )
    default:
      return <Badge label="" className="closed-chip" />
  }
}

export const renderUnreadComments = (count) => (
  <div className="unread-comments-div">
    <div className="circle">
      <div className="text">{count}</div>
    </div>
  </div>
)

export const rowIndex = (index, page, pageSize) => (page - 1) * pageSize + index

let actionChange = ''

export const InferCommentAction = (status) => {
  if (status === 'open') actionChange = 'open'
  if (status === 'in_progress') actionChange = 'in_progress'
  if (status === 'closed') actionChange = 'closed'
  if (status === 'completed') actionChange = 'completed'
  if (status === 'reopened') actionChange = 're-opened'
}

export const fetchStatusIcon = () => {
  if (actionChange === 'open') return <Circle fontSize="10" style={{ color: '#C6C6C6' }} />
  if (actionChange === 'in_progress') return <AccessTime fontSize="10" style={{ color: '#EAE40F' }} />
  if (actionChange === 'completed') return <CheckCircle fontSize="10" style={{ color: '#68CC56' }} />

  if (actionChange === 're-opened') return <img alt="" src={ReOpen} width={18} height={18} />
}

export const renderTimelineColor = () => {
  if (actionChange === 'open') return '#C6C6C6'
  if (actionChange === 'in_progress') return '#EAE40F'
  if (actionChange === 'completed') return '#68CC56'
  if (actionChange === 're-opened') return '#ff8B00'
}

export const findMeInTicketParticipants = (creator, assignee, userId) => {
  if (assignee?.id === userId) return 'Assignee'
  if (creator.id === userId) return 'Creator'

  return 'CC List'
}

export const checkTicketOverdue = (date, format = 'MMM DD YYYY hh:mm a') =>
  differenceBetweenMinutes(moment(new Date(date)).format(format), moment(new Date()).format(format)) > 0

export const ticketCompletedOrClosed = (ticketStatus) => {
  return ticketStatus === 'completed' || ticketStatus === 'closed'
}

export const isCreatorOrAssigneeOrHavePermission = (ticket, currentUser, userPermissions) => {
  return (
    ticket.creator.id === currentUser.id ||
    ticket.assignee?.id === currentUser.id ||
    checkPermission(userPermissions, 'tickets', 'general', 'ticket_dashboard').isFull() ||
    (checkPermission(userPermissions, 'tickets', 'general', 'ticket_dashboard').isRestricted() &&
      (currentUser.moderatable_ids.includes(ticket.category.id) ||
        currentUser.moderatable_ids_as_staff_member.includes(ticket.category.id)))
  )
}

export const defaultTicketView = [{ value: 'assigned_to' }, { value: 'my_approvals' }, { value: 'unread' }]

export const ticketStatusOptions = [
  { value: 2, label: 'Closed' },
  { value: 3, label: 'Completed' },
  { value: 1, label: 'In Progress' },
  { value: '0', label: 'Open' },
  { value: 4, label: 'Re-Opened' },
  { value: 'overdue', label: 'Overdue' },
  { value: 'overdue_and_completed', label: 'Overdue Completed' },
  { value: 'overdue_asc', label: 'Overdue (Asc)' }
]

export const getMenuOption = (ticketStatus) => {
  var object = {}
  if (ticketStatus === 'open') {
    object = { key: 'in_progress', text: 'Mark as In Progress' }
  } else if (ticketStatus === 'in_progress' || ticketStatus === 'reopened') {
    object = { key: 'completed', text: 'Mark as Completed' }
  } else if (ticketCompletedOrClosed(ticketStatus)) {
    object = { key: 'reopened', text: 'Mark as Re-Opened' }
  }
  return object
}

export const displayTicketStatus = (creator, assignee, ticket_seen, userId, property, onClick, submission) => {
  let tooltipTitle, iconName

  if (
    (assignee?.id === userId || findMeInTicketParticipants(creator, assignee, userId)) &&
    property === 'ticket-inbox'
  ) {
    let classNameForIcon = 'edit icon cursor-pointer'

    if (ticket_seen) {
      tooltipTitle = 'Mark as unread'
      iconName = Drafts
    } else {
      tooltipTitle = 'Mark as read'
      iconName = Email
    }

    const ComponentToRender = iconName == Drafts ? Drafts : Email

    return (
      <Tooltip title={tooltipTitle} disabled={submission} className={classNameForIcon}>
        {property === 'inbox' ? (
          <Button id="ticket-read-status" onClick={onClick}>
            <ComponentToRender />
          </Button>
        ) : (
          <Button className={classNameForIcon} onClick={onClick}>
            <ComponentToRender />
          </Button>
        )}
      </Tooltip>
    )
  }
}

export const isTicketsTabs = (path) => ['/tickets', '/tickets/sent', '/tickets/dashboard'].includes(path) || false

export const isTicketOverDueStatus = (status) => ['open', 'in_progress', 'reopened'].includes(status)

export const ticketSortingFilterOptions = [
  { label: 'Recent Activity', value: 'recent_activity', isAccess: true },
  { label: 'Newest to Oldest', value: 'newest', isAccess: true },
  { label: 'Oldest to Newest', value: 'oldest', isAccess: true },
  { label: 'Assignee Name (A-Z)', value: 'assignee_name_asc', isAccess: true },
  { label: 'Assignee Name (Z-A)', value: 'assignee_name_desc', isAccess: true }
]

export const ticketSortFilterByCreatorOptions = [
  { label: 'Creator Name (A-Z)', value: 'creator_name_asc', isAccess: true },
  { label: 'Creator Name (Z-A)', value: 'creator_name_desc', isAccess: true }
]

export const showApprovalActions = (userId, approvalRow) => {
  return approvalRow.approver.id === userId && approvalRow.approver.status === 'pending'
}

export const checkCurrentFiltersStatus = (searchParam) =>
  checkArrayPresence(searchParam.approval) ||
  checkArrayPresence(searchParam.assigned_to) ||
  checkArrayPresence(searchParam.category) ||
  searchParam.created_by !== '' ||
  checkArrayPresence(searchParam.department) ||
  searchParam.end_date !== '' ||
  checkArrayPresence(searchParam.location_ids) ||
  searchParam.start_date !== '' ||
  checkArrayPresence(searchParam.status) ||
  searchParam.title !== ''

export const ticketAdminFilters = [
  {
    label: 'Departments',
    value: 'departments',
    component: 'selectWrapper',
    placeholder: 'Select Department(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Status',
    value: 'status',
    component: 'buttonGroupWrapper',
    buttonGroup: [
      { label: 'Enable', value: 'false' },
      { label: 'Disable', value: 'true' }
    ]
  },
  {
    label: 'Approver',
    value: 'approver',
    component: 'selectWrapper',
    placeholder: 'Select Approver(s)',
    multiOption: true,
    userSearchable: true
  },
  {
    label: 'Required Days',
    value: 'days_required',
    type: 'number',
    min: 0,
    component: 'customInput',
    placeholder: 'Required Days'
  },
  {
    label: 'Department Moderator',
    value: 'department_moderators',
    component: 'selectWrapper',
    placeholder: 'Select Department Moderator(s)',
    multiOption: true,
    userSearchable: true
  },
  {
    label: 'Ticket Moderator',
    value: 'ticket_moderators',
    component: 'selectWrapper',
    placeholder: 'Select Ticket Moderator(s)',
    multiOption: true,
    userSearchable: true
  },
  {
    label: 'Ticket Staff',
    value: 'ticket_staff',
    component: 'selectWrapper',
    placeholder: 'Select Ticket Staff(s)',
    multiOption: true,
    userSearchable: true
  }
]

export const assignedTicketFilters = (userID) => {
  return [
    {
      label: 'Mentioned',
      value: 'mentioned',
      component: 'customCheckBoxWrapper',
      inActive: ['my_action_items'],
      tooltipText: 'This option cannot be used when \u0022My Action item\u0022 bit is checked'
    },
    {
      label: 'Ticket Category',
      value: 'category',
      component: 'selectWrapper',
      placeholder: 'Select Category(s)',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Ticket Priority',
      value: 'priority',
      component: 'selectWrapper',
      placeholder: 'Select Priority(s)',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Ticket Status',
      value: 'status',
      component: 'selectWrapper',
      placeholder: 'Select Status(s)',
      multiOption: true,
      allCheck: true,
      inActive: ['my_action_items'],
      tooltipText: 'This option cannot be used when \u0022My Action item\u0022 bit is checked'
    },
    {
      label: 'Approval Status',
      value: 'approval',
      component: 'selectWrapper',
      placeholder: 'Select Approval',
      multiOption: true,
      allCheck: true,
      inActive: ['my_action_items'],
      tooltipText: 'This option cannot be used when \u0022My Action item\u0022 bit is checked'
    },
    {
      label: 'Location',
      value: 'location_ids',
      component: 'selectWrapper',
      placeholder: 'Select Location(s)',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Assigned Only',
      value: 'assigned_to',
      checkBoxValue: userID,
      component: 'customCheckBoxWrapper',
      inActive: ['my_action_items'],
      tooltipText: 'This option cannot be used when \u0022My Action item\u0022 bit is checked'
    },
    {
      label: 'My Approvals',
      value: 'my_approvals',
      component: 'customCheckBoxWrapper',
      inActive: ['my_action_items'],
      tooltipText: 'This option cannot be used when \u0022My Action item\u0022 bit is checked'
    },
    {
      label: 'Unread',
      value: 'unread',
      component: 'customCheckBoxWrapper'
    }
  ]
}

export const sentTicketTabFilters = [
  {
    label: 'Ticket Category',
    value: 'category',
    component: 'selectWrapper',
    placeholder: 'Select Category(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Ticket Priority',
    value: 'priority',
    component: 'selectWrapper',
    placeholder: 'Select Priority(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Ticket Status',
    value: 'status',
    component: 'selectWrapper',
    placeholder: 'Select Status(s)',
    multiOption: true,
    allCheck: true
  }
]

export const ticketsDashboardFilters = (userPermissions) => {
  let filters = [
    {
      label: 'Created By',
      value: 'created_by',
      component: 'selectWrapper',
      placeholder: 'Select Creator',
      userSearchable: true,
      optionFilters: { offboarded_users: true }
    },
    {
      label: 'Search By Title',
      value: 'title',
      component: 'customInput',
      placeholder: 'Title'
    },
    {
      label: 'Assigned To',
      value: 'assigned_to',
      component: 'selectWrapper',
      placeholder: 'Select Assigned To',
      multiOption: true,
      userSearchable: true,
      optionFilters: { offboarded_users: true }
    },
    {
      label: 'Date Range',
      value: 'custom_date_range',
      fromDateValue: 'start_date',
      toDateValue: 'end_date',
      component: 'dateRangePickerWrapper'
    },
    {
      label: 'Category',
      value: 'category',
      component: 'selectWrapper',
      placeholder: 'Select Category(s)',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Status',
      value: 'status',
      component: 'selectWrapper',
      placeholder: 'Select Status(s)',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Approval',
      value: 'approval',
      component: 'selectWrapper',
      placeholder: 'Select Approval(s)',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'My Approvals',
      value: 'my_approvals',
      component: 'customCheckBoxWrapper'
    },
    {
      label: 'Location',
      value: 'location_ids',
      component: 'selectWrapper',
      placeholder: 'Select Location(s)',
      multiOption: true,
      allCheck: true
    }
  ]
  if (checkPermission(userPermissions, 'tickets', 'general', 'ticket_dashboard', true).isFull()) {
    filters.unshift({
      label: 'Department',
      value: 'department',
      component: 'selectWrapper',
      placeholder: 'Select Department(s)',
      multiOption: true,
      allCheck: true
    })
  }
  return filters
}

const serializeModeratorParams = (locationDependentMembers, locationBinded) =>
  locationDependentMembers.map((locationModerator) => {
    return {
      moderator_locations_dependent: locationBinded,
      moderators_attributes: locationModerator.moderators_attributes.map((moderator) => moderator.user_id),
      moderator_locations: locationModerator.moderator_locations
    }
  })

const serializeStaffParams = (locationDependentStaff, locationBinded) =>
  locationDependentStaff.map((locationStaff) => {
    return {
      staff_location_dependent: locationBinded,
      staff_attributes: locationStaff.staff_attributes.map((staff) => staff.user_id),
      staff_locations: locationStaff.staff_locations
    }
  })

export const getCategoryInitialValues = (ticketCategory) => {
  return {
    id: ticketCategory.id,
    name: ticketCategory.name,
    department_id: ticketCategory.department_id,
    days_required: ticketCategory.days_required,
    day_preference: ticketCategory.day_preference,
    color: ticketCategory.color.startsWith('#') ? ticketCategory.color : `#${ticketCategory.color}`,
    description: ticketCategory.description,
    department_moderator_id: ticketCategory.department_moderator_user?.id,
    location_binded_moderators: serializeModeratorParams(
      ticketCategory.moderators_attributes,
      ticketCategory.moderator_location_binded
    ),
    location_binded_staff: serializeStaffParams(
      ticketCategory.ticket_staff_attributes,
      ticketCategory.staff_location_binded
    ),
    moderator_location_dependent: ticketCategory.moderator_location_binded,
    staff_location_dependent: ticketCategory.staff_location_binded,
    is_department_moderator: ticketCategory.is_department_moderator,
    moderator_locations: ticketCategory.moderator_locations,
    staff_location: ticketCategory.staff_location,
    approval_required: ticketCategory.approval_required,
    applicable_from: ticketCategory.approval_required
      ? ticketCategory.ticket_approval_timelines[0].applicable_from
      : '',
    approvals_location_binded: ticketCategory.approval_required
      ? ticketCategory.ticket_approval_timelines[0].location_binded
      : false,
    ticket_approval_settings_attributes: ticketCategory.approval_required
      ? ticketCategory.ticket_approval_timelines[0].ticket_approval_settings.map((approver) => ({
          ticket_approval_ids: approver.approvals?.map((user) => user.user_id) ?? [],
          location_approval_required:
            approver.lead_approval_required || approver.pm_approval_required || approver.dynamic_approvals_required,
          approvers_location_id: approver.location ? approver.location.id : null,
          approvals_in_sequence: approver.approvals_in_sequence,
          applicable_approvers: Object.keys(approver).reduce((acc, key) => {
            if (
              approver[key] === true &&
              ['pm_approval_required', 'lead_approval_required', 'dynamic_approvals_required'].includes(key)
            ) {
              acc.push(key)
            }
            return acc
          }, [])
        }))
      : []
  }
}

export const getCategoriesSubmitValues = (category, memberValues) => {
  return {
    name: category.name,
    color: category.color,
    days_required: category.days_required,
    department_id: category.department_id,
    approval_required: category.approval_required,
    description: category.description,
    day_preference: category.day_preference,
    ticket_staff_attributes: memberValues.staff_location_attributes,
    moderators_attributes: memberValues.moderators_location_attributes,
    moderator_location_binded: memberValues.moderator_location_binded,
    staff_location_binded: memberValues.staff_location_binded,
    ticket_approval_timelines_attributes: category.approval_required
      ? [
          {
            applicable_from: category.applicable_from,
            approvals_location_binded: category.approvals_location_binded,
            ticket_approval_settings_attributes: category.ticket_approval_settings_attributes.reduce(
              (acc, approvers) => {
                if (approvers.location_approval_required) {
                  acc.push({
                    pm_approval_required: approvers.applicable_approvers.includes('pm_approval_required'),
                    lead_approval_required: approvers.applicable_approvers.includes('lead_approval_required'),
                    dynamic_approvals_required: approvers.applicable_approvers.includes('dynamic_approvals_required'),
                    approvals_in_sequence: approvers.approvals_in_sequence,
                    location_id: approvers.approvers_location_id,
                    ticket_approvals_attributes: approvers.ticket_approval_ids?.map((userId) => ({
                      user_id: userId
                    }))
                  })
                }
                return acc
              },
              []
            )
          }
        ]
      : []
  }
}

export const triggerOptions = [
  {
    id: 0,
    label: 'Immediate',
    value: 'immediate'
  },
  {
    id: 1,
    label: `After: Resolution time breach`,
    value: 'after'
  }
]
export const actionOptions = [
  { id: 0, label: 'Notification', value: 'notification' },
  { id: 1, label: 'Add CC User', value: 'add_cc_user' }
]
export const assigneeTypeOptions = [
  { id: 3, label: 'Assignee Lead', value: 'assignee_lead' },
  { id: 2, label: 'Assignee Manager', value: 'assignee_manager' },
  { id: 4, label: 'Assignee Reporting', value: 'assignee_reporting' },
  { id: 1, label: 'Category Moderator', value: 'category_moderator' },
  { id: 5, label: 'Custom User', value: 'custom_user' },
  { id: 0, label: 'Department Moderator', value: 'department_moderator' }
]

export const actionFilters = ['actions', 'departments']

export const actionTableHeaders = ['Action', 'Department Name', 'Trigger', 'To']

export const actionMainFilters = ['action', 'department']

export const dayPreferenceOptions = [
  { label: 'Working days', value: 'working_days' },
  { label: 'Calendar days', value: 'calendar_days' }
]

export const currentUserRemovedAndParticipantsExists = (ticket, userId) =>
  checkArrayPresence(ticket?.participants) && checkCurrentUserRemoved(ticket, userId)

export const checkCurrentUserRemoved = (ticket, userId) => {
  const userIds = ticket?.participants?.filter((user) => user.user.id === userId)

  return checkArrayEmpty(userIds)
}

export const participantsOfTicket = (ticket, userId) => {
  const approver = ticket?.ticket_approvals?.filter((user) => user.approver.id === userId)
  const ticketStaff = ticket?.category_staff?.filter((user) => user.user_id === userId)
  const ticketModerator = ticket?.category_moderators?.filter((user) => user.user_id === userId)

  return (
    checkArrayPresence(approver) ||
    checkArrayPresence(ticketStaff) ||
    checkArrayPresence(ticketModerator) ||
    ticket.assignee?.id === userId ||
    ticket.creator.id === userId
  )
}

export const getFilteredTickets = (tickets, ticketID) => tickets.filter((ticket) => ticket.id !== ticketID)

export const clearSearchParams = () => {
  const url = new URL(window.location)
  const urlSearchParams = new URLSearchParams()
  url.search = urlSearchParams
  window.history.replaceState(null, '', `${url.pathname + url.search}`)
}

export const ticketCatrgoryUrl = (getDashboardCategories, search_params, detailed_data, csv_data, base_url) => {
  let urlString = `ticket_categories?moderatable=${getDashboardCategories}&${stringify(search_params, {
    arrayFormat: 'brackets'
  })}&detailed_data=${detailed_data}`
  csv_data && (urlString = urlString + `&csv_data=${csv_data}&base_url=${base_url}`)
  return urlString
}

export const scheduleEmailTenure = [
  { label: 'Yearly', value: 'yearly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Daily', value: 'daily' }
]

export const scheduleEmailReportRange = [
  { label: 'Last Day', value: 'last_day' },
  { label: 'Last 7 Days', value: 'last_7_day' },
  { label: 'Last 14 Days', value: 'last_14_days' },
  { label: 'Last 30 Days', value: 'last_30_days' },
  { label: 'Last Quarter', value: 'last_quarter' },
  { label: 'Last Year', value: 'last_year' }
]

export const weeklyScheduleOptions = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' }
]

export const firstAndLastDateOptions = (type) =>
  type === 'quarterly'
    ? [
        { label: 'First Day of Quarter', value: 'first_day_of_quarter' },
        { label: 'Last Day of Quarter', value: 'last_day_of_quarter' }
      ]
    : [
        { label: 'First Day of Month', value: 'first_day_of_month' },
        { label: 'Last Day of Month', value: 'last_day_of_month' },
        { label: 'Custom', value: 'custom' }
      ]

export const emailScheduleHeaders = ['Sr.#', 'Frequency', 'Date & Time', 'Report Date Range', 'Actions']
